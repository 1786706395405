export interface Office
{
	id: number;
	winWastePos?: number;
	winWasteVersion: string;
	customerId?: number;
	supplierId?: number;
	producerId?: number;
	winWasteTown: string;
	winWasteProvince: string;
	headOffice: boolean;
	referent: string;
	address: string;
	streetNumber: string;
	provinceIstatCode: string;
	provinceName: string;
	townIstatCode: string;
	townName: string;
	postalCode: string;
	latitude?: number;
	longitude?: number;
	phoneNumber: string;
	mobilePhoneNumber: string;
	faxNumber: string;
	email: string;
	pecEmail: string;
	notes: string;
	highlightNotes: string;
}

export enum Scopes
{
	Spurghi = 1,
	Trasporti = 2
}

export enum BusinessAreas
{
	Disidratazioni = 1,
	Eternit = 2,
	Servizi = 3,
	Spurghi = 4,
	Trasporto = 5,
	Ufficio = 6,
	Laboratorio = 7
}

export enum TransportMissionType
{
	Cisterna = 1,
	Cassoni = 2,
	Etherit = 3
}

export enum TransportMissionStatus
{
	InCreazione = 1,
	Programmata = 2,
	Completata = 3,
	Annullata = 4
}

export enum TransportMissionActivityStatus
{
	InCreazione = 1,
	Programmata = 2,
	Completata = 3,
	Annullata = 4
}

export enum TransportMissionActivityType
{
	Carico = 1,
	Scarico = 2,
	Rientro = 3,
	Intermediazione = 4,
	ConsegnaCassoni = 5,
	RitiroCassoni = 6,
	SostituzioneCassoni = 7,
	SpostamentoCentrifuga = 8,
	NotaDiLavoro = 9,
	Manutenzione = 10,
	TrasportoMerci = 11
}

export enum DehidrationInterventionStatus
{
	InCreazione = 1,
	Programmata = 2,
	Completata = 3,
	Annullata = 4
}

export enum EnumLockerReservationStatus
{
	InCompilazione = 1,
	InGestione = 2,
	InCaricamento = 3,
	InAttesaRitiro = 4,
	Ritirata = 5,
	Annullata = 255
}

export const defaultMapStyle = [
	{
		"featureType": "administrative",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#688696"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "all",
		"stylers": [
			{
				"color": "#f2f2f2"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "poi.attraction",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.business",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.government",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.medical",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "poi.school",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "all",
		"stylers": [
			{
				"saturation": -100
			},
			{
				"lightness": 45
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "all",
		"stylers": [
			{
				"color": "#46bcec"
			},
			{
				"visibility": "on"
			}
		]
	}
];

export interface PurgeWorkType
{
	id: number;
	name: string;
	options: PurgeWorkTypeOption[];
}
export interface PurgeWorkTypeOption
{
	id: number;
	name: string;
	value: number;
	description: string;
}

export interface VehicleSize
{
	id: number;
	vehicleTypeId: number;
	name: string;
	description: string;
}

export interface VehicleType
{
	id: number;
	name: string;
}

