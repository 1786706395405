
.report-legend
{
	display: block;
	list-style: none;
	margin: 10px 0 0 0;
	padding: 10px 0 0 0;
	border-top: 1px dashed #ddd;

	> li
	{
		display: block;
		float: left;
		margin-right: 16px;
		font-size: 15px;

		&:last-child
		{
			margin-right: 0;
		}
	}
}

.reporting-container
{
	position: relative;
	background: #fff;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 10px;

	.downloading-panel
	{
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.9);
		justify-content: center;
		z-index: 99;

		> div
		{
			align-self: flex-start;
			text-align: center;
			color: #676767;
			font-size: 24px;
			margin-top: 30px;

			em
			{
				display: block;
				margin: 0 auto;
				font-size: 60px;
				color: #ec407a;
				margin-right: 10px;
			}

			span
			{
				display: block;
				margin: 30px auto 0;
			}
		}
	}

	.export-matrix-link
	{
		position: absolute;
		left: 0;
		top: 0;
		display: inline-block;
		font-size: 14px;
		color: #ec407a;
		border: 1px solid #ddd;
		border-left: 0;
		border-top: 0;
		border-radius: 10px 0 10px 0;
		padding: 5px 10px;
		background: #fff;
		z-index: 20;

		i
		{
			margin-right: 10px;
		}
	}

	.reporting-row
	{
		display: flex;
		margin-bottom: 10px;

		> .reporting-row--right
		{
			flex: 0 0 auto;

			.buttons-list
			{
				display: block;
				list-style: none;
				margin: 180px 0 0 0;
				padding: 0;

				> li
				{
					display: flex;
					padding: 12px 20px;
					height: 110px;
					border-right: 1px solid #ddd;
					flex-direction: column;
					justify-content: center;

					> a
					{
						display: flex;
						align-items: center;
						color: #28BEBD;
						margin-bottom: 10px;

						&:first-child
						{
							color: #EC407A;
						}

						&:last-child
						{
							margin-bottom: 0;
						}

						i
						{
							font-size: 14px;
							margin-right: 5px;
						}
					}
				}
			}
		}

		> .reporting-row--left
		{
			flex: 0 0 auto;

			.entities-list
			{
				display: block;
				list-style: none;
				margin: 180px 0 0 0;
				padding: 0;

				> li
				{
					display: flex;
					padding: 12px 10px;
					height: 110px;
					border-right: 1px solid #ddd;

					> div
					{
						display: flex;
						align-self: center;

						> a
						{
							max-width: 120px;
							display: block;
							flex: 1;
						}

						mat-checkbox
						{
							align-self: center;
							margin-right: 10px;

							.mat-checkbox-layout
							{
								margin-bottom: 0 !important;
							}
						}
					}

					&:nth-child(even)
					{
						background: #f2f2f2;
					}
				}
			}
		}

		> .reporting-row--content
		{
			flex: 1;
			white-space: nowrap;
			overflow-x: auto;

			.drag-scroll-content
			{
				height: auto !important;
				overflow: hidden;
			}

			.filetypes-list
			{
				display: flex;
				list-style: none;
				margin: 0;
				padding: 0;

				> li
				{
					display: flex;
					flex-shrink: 0;
					width: 140px;
					min-width: 140px;
					padding: 12px 0 0 0;
					height: 110px;
					text-align: center;
					border-right: 1px solid #ddd;
					cursor: -webkit-grab !important;
					cursor: grab !important;

					> div
					{
						flex: 1;
						align-self: flex-end;
						margin-bottom: 10px;
						/* checkbox */
						.mat-checkbox
						{
							display: block;
							margin-top: 5px;
							margin-bottom: 6px;

							.mat-checkbox-layout
							{
								margin-bottom: 0 !important;
							}
							/* valid */
							&.isValid
							{
								.mat-checkbox-frame
								{
									border-color: #2db000 !important;
								}

								&.mat-checkbox-checked .mat-checkbox-background
								{
									background-color: #2db000;
									border: 1px solid #2db000;
								}
							}
							/* not valid */
							&.isInvalid
							{
								.mat-checkbox-frame
								{
									border-color: #bf1212 !important;
								}

								&.mat-checkbox-checked .mat-checkbox-background
								{
									background-color: #bf1212;
									border: 1px solid #bf1212;
								}
							}
							/*disabled*/
							&.isDisabled
							{
								cursor: not-allowed;

								.mat-checkbox-frame
								{
									border-color: #eee !important;
								}

								&.mat-checkbox-checked .mat-checkbox-background
								{
									background-color: #eee;
									border: 1px solid #eee;
								}
							}
						}
						/* quicklinks */
						.quicklinks-list
						{
							display: block;
							list-style: none;
							margin: 0;
							padding: 0;

							> li
							{
								flex: 1;
								margin: 0 5px;
								font-size: 12px;

								> a
								{
									display: block;
									white-space: normal;
									line-height: 14px;
								}
							}
						}

						> small
						{
							/* valid */
							&.isValid
							{
								color: #2db000;
							}
							/* not valid */
							&.isInvalid
							{
								color: #bf1212;
							}
						}
					}

					&.even
					{
						background: #f2f2f2;
					}

					&:empty
					{
						border: 0;
					}
				}

				&.filetypes-list--header
				{
					> li
					{
						position: relative;
						width: 140px;
						height: 180px;
						text-align: left;
						border: 0;
						border-bottom: 1px solid #ddd;
						border-radius: 10px;
						justify-content: center;

						> span
						{
							display: block;
							width: 300px;
							position: absolute;
							bottom: 130px;
							left: 50%;
							margin-left: -15px;
							-webkit-transform: rotate(-20deg);
							transform: rotate(-25deg);
							-webkit-transform-origin: bottom center;
							transform-origin: bottom center;
							z-index: 10;
							cursor: pointer;

							> i
							{
								color: #EC407A;
								font-size: 16px;
							}
						}

						&:before
						{
							position: absolute;
							content: "";
							width: 1px;
							border-left: 1px solid #ddd;
							height: 30px;
							bottom: 0;
							left: 50%;
						}

						> ul.column-actions-list
						{
							display: flex;
							list-style: none;
							margin: 0;
							padding: 0;
							height: 60px;
							width: 100%;
							align-self: flex-end;

							> li
							{
								flex: 1;
								margin: 0;
								align-self: flex-end;
								text-align: center;

								&:first-child
								{
									margin-left: 15px;

									> a
									{
										color: #EC407A;
									}
								}

								&:nth-child(2)
								{
									align-self: flex-start;
								}

								&:last-child
								{
									margin-right: 15px;

									> a
									{
										color: #28BEBD;
									}
								}

								> a
								{
									display: flex;
									align-items: center;

									> i
									{
										font-size: 14px;
										margin-right: 5px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.checkbox-list
{
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	max-height: 256px;
	overflow-y: auto;

	> li
	{
		display: block;
		margin-bottom: 6px;
		padding-bottom: 6px;
		border-bottom: 1px dashed #ddd;
	}
}

.filter-group
{
	border-top: 1px solid #ddd;
	padding: 0 10px;
	border-radius: 10px;
}

.form-group-left-border
{
	padding-left: 20px;
	border-left: 2px dashed #ddd;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
	0%
	{
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		color: #EC407A;
	}

	50%
	{
		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
		color: #42a5f5;
	}

	100%
	{
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
		color: #EC407A;
	}
}

@keyframes rotating
{
	0%
	{
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		color: #EC407A;
	}

	50%
	{
		-ms-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
		color: #42a5f5;
	}

	100%
	{
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
		color: #EC407A;
	}
}

.rotating
{
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}
