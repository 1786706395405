<!--locker container-->
<div class="locker_container" *ngIf="locker">

	<!--locker-->
	<div class="locker">
		<div *ngFor="let column of locker.columns" class="locker_column">
			<a *ngFor="let drawer of column.drawers" class="locker_drawer locker_drawer_size--{{drawer.lockerDrawerSizeId}}" [class.selected]="drawer.selected" (click)="onDrawerClick(drawer)"
				[class.locker_drawer_hmi]="drawer.isHmi"
				[class.locker_drawer_empty]="drawer.empty">

				<!--drawer-->
				<ng-container *ngIf="!drawer.isHmi">

					<!--drawer position and size-->
					<div class="locker_drawer_position">
						{{drawer.column}}:{{drawer.row}}
					</div>

					<!--drawer info-->
					<ul class="locker_drawer_info">

						<!--drawer open/closed status-->
						<li class="locker_drawer_open_status locker_drawer_open_status--{{drawer.closed}}">

							<!--closed-->
							<mat-icon *ngIf="drawer.closed" matTooltip="Chiuso">lock</mat-icon>

							<!--open-->
							<mat-icon *ngIf="!drawer.closed" matTooltip="Aperto">lock_open</mat-icon>

						</li>

						<!--drawer empty/full status-->
						<li class="locker_drawer_empty_status" *ngIf="!drawer.empty">
							<mat-icon matTooltip="Pieno">handyman</mat-icon>
						</li>

					</ul>

				</ng-container>

				<!--hmi-->
				<ng-container *ngIf="drawer.isHmi">
					HMI
				</ng-container>

			</a>
		</div>
	</div>
</div>