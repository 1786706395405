import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Locker, LockerListItem } from './lockers.models';

@Injectable()
export class LockersService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get list
	public getList(): Observable<LockerListItem[]>
	{
		return this.http.get<LockerListItem[]>(this.baseUrl + 'api/warehouse/lockers/getList');
	}

	// get single
	public getSingle(id: number): Observable<Locker>
	{
		return this.http.get<Locker>(this.baseUrl + `api/warehouse/lockers/getSingle/${id}`);
	}

	// sync locker status
	public syncLockerStatus(id: number)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockers/syncLockerStatus/${id}`, null);
	}
}