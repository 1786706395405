import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { SetupService } from '../../../../../shared/services/app/setup.service';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { VehicleType as EnumVehicleType } from '../../../enums';
import { VehicleSize, VehicleType } from '../../../shared.models';

@Component({
	selector: 'vehicle-selector',
	templateUrl: './selector.component.html',
	styleUrl: './selector.component.scss'
})

export class VehicleSelectorComponent implements OnInit
{
	// viewchildren
	@ViewChild('modalAutofocusRef') modalAutofocusRef: ElementRef;

	// I/O
	@Output() vehicleSelectionCallback: EventEmitter<any> = new EventEmitter();

	// input filter
	public inputFilters: any;

	// variables
	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean;
	public _vehicleTypes: VehicleType[];
	public _vehicleSizes: VehicleSize[];

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			name: [null],
			vehicleTypeId: [null],
			vehicleSizeId: [null],
			seats: [null],
			adr: [null],
			maxHeight: [null],
			maxPipeDiameter: [null],
			optionsId: [null],
			status: [true]
		}
	);
	get vehicleTypeId() { return this._searchForm.get('vehicleTypeId'); }
	get vehicleSizeId() { return this._searchForm.get('vehicleSizeId'); }
	get seats() { return this._searchForm.get('seats'); }
	get adr() { return this._searchForm.get('adr'); }
	get maxHeight() { return this._searchForm.get('maxHeight'); }
	get maxPipeDiameter() { return this._searchForm.get('maxPipeDiameter'); }
	get optionsId() { return this._searchForm.get('optionsId'); }
	get status() { return this._searchForm.get('status'); }

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private setupService: SetupService,
			private fb: FormBuilder,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit(): void
	{
		// initialization
		let _vehicleTypesObs = this.setupService.getVehicleTypes();

		forkJoin([_vehicleTypesObs]).subscribe(results =>
		{
			this._vehicleTypes = results[0];

			// patching pre-filters
			if (this.inputFilters)
			{
				this._searchForm.patchValue(this.inputFilters);

				// check: vehiclet type patched
				if (this.vehicleTypeId.value)
					this.loadVehicleSizes();
			}

			// get list
			this.getList();
		});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// get vehicles list
	getList()
	{
		this._showTable = false;

		const _filters =
		{
			...this._searchForm.value
		};

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 5,
			Url: this._baseUrl + 'api/maintenances/vehicles/getSelectorList',
			Order: [0, 'asc'],
			Columns:
				[
					{
						title: 'Targa', data: 'licensePlate', className: 'all', width: '150px', render: (data, type, row) =>
						{
							let result = '<span>' + data + '</span>';
							if (row['vehicleTypeName'])
								result += '<small class="table_small_info">' + row['vehicleTypeName'] + '</small>';

							return result;
						}
					},
					{
						title: 'Caratteristiche', data: 'name', className: 'min-tablet', orderable: false, render: (data, type, row) =>
						{
							let features = [];
							let currentOptions: number[] = this.optionsId.value;

							if (row['vehicleSizeName'])
								features.push({ title: 'Taglia', description: row['vehicleSizeName'], active: (row['vehicleSizeId'] <= this.vehicleSizeId.value) });
							if (row['seats'])
								features.push({ title: 'Posti', description: row['seats'], active: (row['seats'] === this.seats.value) });
							if (row['height'])
								features.push({ title: 'Altezza', description: row['height'] + ' cm', active: (row['height'] <= this.maxHeight.value) });
							if (row['pipeDiameter'])
								features.push({ title: 'Diametro tubazioni max.', description: row['pipeDiameter'] + ' mm', active: (row['pipeDiameter'] >= this.maxPipeDiameter.value) });
							if (row['industrialPurge'])
								features.push({ title: 'Spurgo industriale', description: null, active: (currentOptions?.indexOf(1) >= 0) });
							if (row['publicBodyPurge'])
								features.push({ title: 'Spurgo enti pubblici', description: null, active: (currentOptions?.indexOf(2) >= 0) });
							if (row['drainsTrunk'])
								features.push({ title: 'Proboscide per caditoie', description: null, active: (currentOptions?.indexOf(3) >= 0) });
							if (row['crane'])
								features.push({ title: 'Furgone con gru', description: null, active: (currentOptions?.indexOf(8) >= 0) });
							if (row['milanoAreaB'])
								features.push({ title: 'Area B Milano', description: null, active: (currentOptions?.indexOf(10) >= 0) });
							if (row['bergamoZtl'])
								features.push({ title: 'ZTL Bergamo', description: null, active: (currentOptions?.indexOf(11) >= 0) });
							if (row['teleinspection'])
								features.push({ title: 'Teleispezione', description: null, active: (currentOptions?.indexOf(4) >= 0) });
							if (row['restoration'])
								features.push({ title: 'Risanamento', description: null, active: (currentOptions?.indexOf(5) >= 0) });
							if (row['box'])
								features.push({ title: 'Cassone', description: null, active: (currentOptions?.indexOf(6) >= 0) });
							if (row['closed'])
								features.push({ title: 'Chiuso', description: null, active: (currentOptions?.indexOf(7) >= 0) });
							if (row['wasteTransport'])
								features.push({ title: 'Trasporto rifiuti', description: null, active: (currentOptions?.indexOf(9) >= 0) });
							if (row['adr'])
								features.push({ title: 'ADR', description: null, active: (this.adr.value) });

							let result = '<ul class="table_details_list">';
							features.forEach(item => { result += `<li${(item.active ? " class='active'" : '')}><strong>${item.title}${(item.description ? ':' : '')}</strong>${(item.description ? '<span>' + item.description + '</span>' : '')}</li>`; })
							result += '</ul>';

							return result;
						}
					},
					{
						title: 'Prossima&nbsp;manut.', data: 'nextMaintenanceScheduling', className: 'min-tablet', width: '120px', render: (data, type, row) =>
						{
							if (data)
								return this.datepipe.transform(data, 'dd/MM/yyyy HH:mm')

							return '';
						}
					},
					{
						title: '', data: 'id', className: 'all adv-col adv-col-sm', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo automezzo" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.vehicleSelectionCallback.emit({

						// vehicle
						id: data['id'],
						name: data['name'],
						licensePlate: data['licensePlate'],
						siaNumber: data['siaNumber'],
						mounting: data['mounting'],
						adr: data['adr'],

						// maintenance
						nextMaintenanceId: data['nextMaintenanceId'],
						nextMaintenanceScheduling: data['nextMaintenanceScheduling'],

						// iot
						iotDeviceId: data['iotDeviceId'],
						purgeWorkTypeId: data['purgeWorkTypeId']
					});
				});
			},
			DrawCallback: (settings) =>
			{
				setTimeout(() => { this.modalAutofocusRef.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search vehicle
	search()
	{
		this.getList();
	}

	// reset filter
	resetFilter()
	{
		this._searchForm.reset(
			{
				status: true
			});
		this._vehicleSizes = null;

		// getting list
		this.getList();
	}

	// vehicle type selection change
	vehicleTypeSelectionChange()
	{
		this.vehicleSizeId.reset();

		// load vehicle size by type
		this.loadVehicleSizes();
	}

	// load vehicle sizes
	loadVehicleSizes()
	{
		if (this.vehicleTypeId.value)
		{
			this.setupService.getVehicleSizes(this.vehicleTypeId.value).subscribe(result =>
			{
				this._vehicleSizes = result;
			})
		}
		else
			this._vehicleSizes = null;
	}
}