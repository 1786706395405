<ng-container *ngIf="lockerReservation">

	<!--header-->
	<div class="modal-header">
		<h4 class="modal-title float-left">
			<i class="zmdi zmdi-dropbox m-r-10"></i>
			Gestione prenotazione
		</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<!--body-->
	<div class="modal-body p-0">

		<div class="row">

			<!--lockers list-->
			<div class="col-9">

				<ng-container [ngSwitch]="lockerReservation.lockerReservationStatusId">

					<!--in gestione-->
					<ng-container *ngSwitchCase="2">

						<!--lockers list-->
						<div *ngIf="lockersList">
							<mat-tab-group mat-stretch-tabs="false" [selectedIndex]="activeLockerTab" animationDuration="0ms">

								<!--details-->
								<mat-tab *ngFor="let locker of lockersList; let i = index;">

									<!--tab title-->
									<ng-template mat-tab-label>
										<div class="d-flex align-items-center text-secondary">
											<span>{{locker.name}}</span>
										</div>
									</ng-template>

									<!--locker component-->
									<div class="locker_drag_scroll">
										<drag-scroll>
											<locker #lockerRef [id]="locker.id"
													  [canSelectFullDrawers]="false"
													  (drawerClick)="onDrawerClick(i)">
											</locker>
										</drag-scroll>
									</div>

								</mat-tab>
							</mat-tab-group>
						</div>
					</ng-container>

					<!--in caricamento-->
					<div class="p-15" *ngSwitchCase="3">

						<div class="row">

							<!--drawers list-->
							<div class="col-md-4">
								<h3>Procedi con il caricamento della merce</h3>

								<!--drawers list-->
								<form [formGroup]="reservationDrawerForm" (ngSubmit)="updateReservationDrawers()" class="form-horizontal">
									<ul class="loading_drawers_list" formArrayName="reservationDrawers">
										<li [class.reading_active]="reservationDrawer.get('readArticlesEnabled').value" *ngFor="let reservationDrawer of formReservationDrawers.controls; let i = index;">
											<div class="inner">

												<div>
													{{reservationDrawer.get('drawer.column').value}}:{{reservationDrawer.get('drawer.row').value}} - Stato: {{reservationDrawer.get('drawer.closed').value ? 'chiuso' : 'aperto'}} - Contenuto: {{reservationDrawer.get('drawer.empty').value === true ? 'vuoto' : 'pieno'}}
												</div>

												<!--drawer articles-->
												<ul class="mt-4" *ngIf="reservationDrawer.get('articleParts').controls.length > 0">
													<li *ngFor="let articlePartControl of reservationDrawer.get('articleParts').controls; let i = index;">

														<!--article type image-->
														<div *ngIf="articlePartControl.get('article.articleType.photoBase64').value">
															<img [src]="'data:image/png;base64,' + articlePartControl.get('article.articleType.photoBase64').value" title="Fotografia" height="50" />
														</div>

														<!--article part details-->
														<span>Parte: {{articlePartControl.get('id').value}}, Articolo: {{articlePartControl.get('article.id').value}}, Categoria: {{articlePartControl.get('article.articleType.id').value}}</span>

														<mat-icon title="Rimuovi" class="text-danger pointer" (click)="removeReservationDrawerArticlePart(reservationDrawer.get('id').value, articlePartControl.get('id').value, i)">clear</mat-icon>

													</li>
												</ul>
												<p class="alert alert-info mt-2" *ngIf="reservationDrawer.get('articleParts').controls.length === 0">
													Nessun articolo selezionato per questo cassetto
												</p>

												<hr class="mt-10" />

												<!--drawer actions-->
												<ng-container *ngIf="!reservationDrawer.get('readArticlesEnabled').value">
													<button type="button" mat-stroked-button color="accent" (click)="removeLockerReservationDrawer(reservationDrawer.get('id').value)"
															  [disabled]="searchingArticle"
															  *ngIf="formReservationDrawers.controls.length > 1"
															  class="mr-2">
														Rimuovi cassetto
													</button>
													<button type="button" mat-stroked-button color="accent" (click)="openDrawer(reservationDrawer.get('drawer.id').value)"
															  [disabled]="searchingArticle"
															  *ngIf="reservationDrawer.get('drawer.closed').value === true"
															  class="mr-3">
														Apri cassetto
													</button>
													<button type="button" mat-stroked-button color="accent" (click)="startDrawerLoad(reservationDrawer.get('id').value)" [disabled]="searchingArticle">
														Inizia lettura
													</button>
												</ng-container>
												<ng-container *ngIf="reservationDrawer.get('readArticlesEnabled').value">
													<button type="button" mat-stroked-button color="accent" (click)="stopDrawerLoad()" [disabled]="searchingArticle">
														Termina lettura
													</button>
												</ng-container>
											</div>

										</li>
									</ul>

								</form>

							</div>

							<!--read articles-->
							<div class="col-md-4">
								<h3>Lettura articoli</h3>
								<div *ngIf="articleReadersEnabled">

									<!--qrcode reader-->
									<ngx-scanner-qrcode #qrCodeScanner="scanner" (event)="qrCodeRead($event)"></ngx-scanner-qrcode>

									<!--rfid input-->
									<input #rfidInput (blur)="rfidInputBlur($event)" class="rfid_input" />

								</div>
							</div>

							<!--article list-->
							<div class="col-md-4">
								<h3>Articoli magazzino richiesti</h3>
								<p>Nessun articolo specificato</p>
							</div>

						</div>
					</div>

				</ng-container>

			</div>

			<!--details-->
			<div class="col-3">
				<div class="p-15">

					<h3>Dettagli prenotazione</h3>

					<!--reservation details-->
					<ul>
						<li>
							<strong>Titolo: </strong>
							<span>{{lockerReservation.title}}</span>
						</li>
						<li>
							<strong>Descrizione: </strong>
							<span>{{lockerReservation.description}}</span>
						</li>
					</ul>

					<!--selected drawers-->
					<ng-container *ngIf="selectedDrawers && selectedDrawers.length > 0">

						<!--check: locker reservation status-->
						<ng-container [ngSwitch]="lockerReservation.lockerReservationStatusId">

							<!--in gestione-->
							<ng-container *ngSwitchCase="2">

								<h3>{{selectedDrawers.length}} cassett{{selectedDrawers.length === 1 ? 'o' : 'i'}} selezionat{{selectedDrawers.length === 1 ? 'o' : 'i'}}</h3>
								<ul *ngFor="let drawer of selectedDrawers">
									<li>
										<strong>Armadio: </strong>
										<span>{{drawer.lockerName}}</span>
									</li>
									<li>
										<strong>Posizione: </strong>
										<span>{{drawer.column}}:{{drawer.row}}</span>
									</li>
								</ul>

								<!--no drawer selected-->
								<ng-container *ngIf="!selectedDrawers || selectedDrawers.length === 0">
									<h3>Seleziona uno o più cassetti</h3>
									<br /><small>E' possibile selezionare solamente cassetti vuoti</small>
									<p>Nessun cassetto selezionato</p>
								</ng-container>

							</ng-container>

							<!--in caricamento-->
							<ng-container *ngSwitchCase="3">

							</ng-container>

						</ng-container>
					</ng-container>

				</div>
			</div>

		</div>
	</div>

	<div class="modal-footer">

		<!--hide modal-->
		<button type="button" mat-button class="mr-2" (click)="hideModal()">
			Chiudi
		</button>

		<!--reservation management-->
		<ng-container *ngIf="lockerReservation.lockerReservationStatusId === 2">
			<button type="button" (click)="startLockerDrawerLoad()" mat-raised-button color="accent" [disabled]="!selectedDrawers || selectedDrawers.length === 0">
				Avvia procedura di caricamento
			</button>
		</ng-container>
		
		<!--drawer loading-->
		<ng-container *ngIf="lockerReservation.lockerReservationStatusId === 3">
			<button type="button" mat-stroked-button color="accent" class="ml-10" (click)="rollbackToManagement()">
				Torna alla selezione cassetti
			</button>
			<button type="button" mat-flat-button color="accent" class="ml-10" (click)="completeReservationLoadActivity()">
				Completa prenotazione
			</button>
		</ng-container>
		
	</div>

</ng-container>