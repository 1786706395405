export interface PurgeWorkTeam
{
	id: number;
	lead: boolean;
	employees: PurgeWorkTeamEmployee[];
	vehicles: PurgeWorkTeamVehicle[];
	articleParts?: PurgeWorkTeamArticlePart[];
}
export interface PurgeWorkTeamEmployee
{
	id: number;
	teamLeader: boolean;
	firstName: string;
	lastName: string;
}
export interface PurgeWorkTeamVehicle
{
	id: number;
	licensePlate: string;
	name: string;
	mounting: string;

	iotDeviceId: string;
	purgeWorkTypeId: number;
	purgeWorkTypeOptionId: number;
}
export interface PurgeWorkTeamArticlePart
{
	id: number;
}

export enum PurgeWorkTeamLayout
{
	PurgeWorkGroup,
	Intervention,
	PurgeNote
}