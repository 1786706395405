import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Locker, LockerDrawer } from '../lockers.models';
import { LockersService } from '../lockers.service';

@Component({
	selector: 'locker',
	templateUrl: './locker.component.html',
	styleUrl: './locker.component.scss'
})

export class LockerComponent implements OnInit
{
	// I/O
	@Input() id: number;
	@Input() canSelectFullDrawers: boolean = false;
	@Output() drawerClick: EventEmitter<LockerDrawer> = new EventEmitter<LockerDrawer>();

	// variables
	public locker: Locker;
	public selectedDrawers: LockerDrawer[] = [];

	// constructor
	constructor
		(
			public lockersService: LockersService
		)
	{

	}

	// init
	ngOnInit()
	{
		// load locker
		this.loadLocker();
	}

	// load locker
	loadLocker()
	{
		this.lockersService.getSingle(this.id).subscribe(
			{
				next: (result) =>
				{
					this.locker = result;
				}
			}
		)
	}

	// on drawer click
	onDrawerClick(drawer: LockerDrawer)
	{
		// check: not hmi
		if (!drawer.isHmi)
		{
			if ((!this.canSelectFullDrawers && drawer.empty) || this.canSelectFullDrawers)
			{
				if (this.selectedDrawers.find(x => x.id === drawer.id))
					this.selectedDrawers = this.selectedDrawers.filter(x => x.id !== drawer.id);
				else
					this.selectedDrawers.push(drawer);

				// update selection status
				this.locker.columns.forEach(column =>
				{
					column.drawers.forEach(drawer =>
					{
						if (this.selectedDrawers.map(x => { return x.id }).indexOf(drawer.id) >= 0)
							drawer.selected = true;
						else
							drawer.selected = false;
					})
				})
			}

			// emit event to parent
			this.drawerClick.emit(drawer);
		}
	}

	// get selected drawers
	getSelectedDrawers(): LockerDrawer[]
	{
		return this.selectedDrawers;
	}
};