import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxScannerQrcodeComponent, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { IdentifiedArticle } from '../articletypes.models';
import { ArticleTypesService } from '../articletypes.service';

@Component({
	selector: 'identify-technological-resource-modal',
	templateUrl: './identify-resource-modal.component.html',
	styleUrls: ['./identify-resource-modal.component.scss']
})

export class CatalogIdentifyResourceModalComponent implements AfterViewInit, OnDestroy
{
	// viewchildren
	@ViewChild('identifiTechnologicalResourceModal') identifiTechnologicalResourceModal;
	@ViewChild('qrCodeScanner') qrCodeScanner: NgxScannerQrcodeComponent;
	@ViewChild('rfidInput', { static: false }) rfidInputElement: ElementRef;

	// variables
	public currentArticle: IdentifiedArticle;
	public searchingArticle = false;
	private rfidScanInterval;
	public articleNotRecognized = false;

	// constructor
	constructor
		(
			private pcodeServices: PCodeServices,
			private articleTypesService: ArticleTypesService,
			private router: Router,
			private cd: ChangeDetectorRef
		)
	{
	}

	// after view init
	ngAfterViewInit(): void
	{

	}

	// destroy
	ngOnDestroy(): void
	{
		// stop qr code scanner
		this.stopQrCodeScanner();

		// stop rfid scanner
		this.stopRfidScanner();
	}

	// show modal
	showModal()
	{
		// initialize component
		this.initialize();

		// show modal
		this.identifiTechnologicalResourceModal.show();
	}

	// inizialize
	initialize()
	{
		// reset
		this.currentArticle = null;
		this.searchingArticle = false;
		this.articleNotRecognized = false;

		this.stopQrCodeScanner();
		this.stopRfidScanner();
		this.cd.detectChanges();

		// reset input value
		this.rfidInputElement.nativeElement.value = '';

		// start qr code scanner
		this.startQrCodeScanner();

		// start rfid scanner
		this.startRfidScan();
	}

	// qr code read
	startQrCodeScanner()
	{
		console.log('Lettura QRCODE in corso...');

		this.qrCodeScanner.start();
	}
	stopQrCodeScanner()
	{
		if (this.qrCodeScanner)
			this.qrCodeScanner.stop();

		console.log('Lettura QRCODE interrotta.');
	}
	qrCodeRead(data: ScannerQRCodeResult[])
	{
		// stop scanner
		this.stopQrCodeScanner();

		this.searchingArticle = true;

		// search article
		this.articleTypesService.identifyResource(data[0].value, null).subscribe({
            next: result =>
			{
				this.currentArticle = result;

				this.searchingArticle = false;
			},

            error: () =>
			{
				this.searchingArticle = false;
			}
        });
	}

	// rfid read
	startRfidScan()
	{
		// start scan
		this.rfidScanInterval = setInterval(() =>
		{
			// focus on input element
			this.rfidInputElement.nativeElement.focus();

			this.readRfidInput();
		}, 500);
	}
	stopRfidScanner()
	{
		console.log('Lettura RFID interrotta.');
		clearInterval(this.rfidScanInterval);
	}
	readRfidInput()
	{
		if (!this.searchingArticle)
		{
			console.log('Lettura RFID in corso...');

			// set rfid read codes
			var readCodes = this.rfidInputElement.nativeElement.value ?
				(
					this.rfidInputElement.nativeElement.value as string)
					.split(/\r?\n|\r|\n/g)
					.filter(x => x !== '' && x.length === 24)
					.filter(this.uniqueFilter)
					.sort((x, y) => (x > y ? 1 : -1)) :
				[];

			this.searchingArticle = true;

			// get first read code
			if (readCodes.length > 0)
			{
				// stop rfid scanner
				this.stopRfidScanner();

				// search article
				this.articleTypesService.identifyResource(null, readCodes[0]).subscribe({
                    next: result =>
					{
						if (result)
						{
							this.currentArticle = result;
						}
						else
						{
							this.articleNotRecognized = true;
						}

						this.searchingArticle = false;
					},

                    error: () =>
					{
						this.searchingArticle = false;
					}
                });
			}
			else
			{
				this.searchingArticle = false;
			}
		}
	}
	rfidInputBlur(e)
	{
		if (e.relatedTarget === null)
			e.target.focus();
	}
	uniqueFilter(value, index, self)
	{
		return self.indexOf(value) === index;
	}

	// go to article details
	goToArticleDetails()
	{
		this.router.navigate
			(
				['/technologicalresources/articlestypes/edit-article', this.currentArticle.catalogArticleTypeId, this.currentArticle.id],
				{ queryParams: this.currentArticle.part ? { showPartId: this.currentArticle.part.id } : null }
			);
	}

	// close modal
	closeModal()
	{
		// stop qr code scanner
		this.stopQrCodeScanner();

		// stop rfid scanner
		this.stopRfidScanner();

		// hide modal
		this.identifiTechnologicalResourceModal.hide()
	}
};