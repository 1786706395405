import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxScannerQrcodeComponent, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { LockerComponent } from '../../lockers/locker/locker.component';
import { LockerDrawer, LockerListItem } from '../../lockers/lockers.models';
import { LockersService } from '../../lockers/lockers.service';
import { LockerReservation, LockerReservationDrawer, LockerReservationDrawerArticlePart } from '../reservations.models';
import { LockerReservationsService } from '../reservations.service';

@Component({
	selector: 'manage-reservation-dialog',
	templateUrl: './manage-reservation-dialog.component.html',
	styleUrls: ["./manage-reservation-dialog.component.scss"]
})

export class ManageLockerReservationDialogComponent implements OnInit, OnDestroy
{
	// I/O
	@Output() loadCompleteCallback: EventEmitter<any> = new EventEmitter();
	@Output() partialUpdateCallback: EventEmitter<any> = new EventEmitter();

	// viewchildren
	@ViewChildren('lockerRef') lockerComponents!: QueryList<LockerComponent>;
	@ViewChild('qrCodeScanner') qrCodeScanner: NgxScannerQrcodeComponent;
	@ViewChild('rfidInput', { static: false }) rfidInputElement: ElementRef;

	// input data
	public inputData: { lockerReservationId: number };

	// variables
	public lockerReservation: LockerReservation;
	public lockersList: LockerListItem[];
	public activeLockerTab: number = 0;
	public selectedDrawers: LockerDrawer[] = [];
	public searchingArticle = false;
	private rfidScanInterval;
	public articleNotRecognized = false;
	public articleReadersEnabled = false;

	// drawer articles form
	public reservationDrawerForm: FormGroup = this.fb.group(
		{
			reservationDrawers: this.fb.array([])
		}
	);
	get formReservationDrawers() { return this.reservationDrawerForm.get('reservationDrawers') as FormArray; }

	// constructor
	constructor
		(
			private lockersService: LockersService,
			private lockerReservationsService: LockerReservationsService,
			private pcodeServices: PCodeServices,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef,
			private fb: FormBuilder,
			private cd: ChangeDetectorRef
		)
	{

	}

	// init
	ngOnInit()
	{
		// initialize component
		this.initialize();
	}

	// destroy
	ngOnDestroy(): void
	{
		// stop active article readers
		this.stopArticleReaders();
	}

	// initialize component
	initialize()
	{
		// stop active article readers
		this.stopArticleReaders();

		// load locker reservation
		this.loadLockerReservation();

		// load lockers list
		this.loadLockersList();
	}

	// load locker reservation
	loadLockerReservation()
	{
		this.lockerReservationsService.getSingle(this.inputData.lockerReservationId).subscribe({
			next: (result) =>
			{
				// current locker reservation
				this.lockerReservation = result;

				// create reservation drawer formArray items
				this.formReservationDrawers.clear();
				this.lockerReservation.drawers.forEach(reservationDrawer =>
				{
					this.createReservationDrawerFormArrayItem(reservationDrawer);
				});

				// patching form
				this.reservationDrawerForm.patchValue(this.lockerReservation);

				// detect dom changes
				this.cd.detectChanges();
			}
		})
	}

	// article readers
	startArticleReaders()
	{
		// reinitialize readers
		this.stopArticleReaders();

		// enable article readers
		this.articleReadersEnabled = true;
		this.cd.detectChanges();

		// reset rfid input value
		this.clearRfidInput();

		// start qr code scanner
		this.startQrCodeScanner();

		// start rfid scanner
		this.startRfidScan();
	}
	stopArticleReaders()
	{
		// reinitialize
		this.stopQrCodeScanner();
		this.stopRfidScanner();

		// disable article readers
		this.articleReadersEnabled = false;
		this.cd.detectChanges();
	}

	// get locker list
	loadLockersList()
	{
		this.lockersService.getList().subscribe({
			next: (result) =>
			{
				this.lockersList = result;
			}
		})
	}

	// on drawer click event
	onDrawerClick(lockerIndex: number)
	{
		// get current locker
		const currentLockerComponent = this.lockerComponents.toArray()[lockerIndex];

		// get locker selected drawers
		this.selectedDrawers = currentLockerComponent.getSelectedDrawers();
	}

	// create reservation drawer formArray item
	createReservationDrawerFormArrayItem(lockerReservationDrawer: LockerReservationDrawer)
	{
		// new reservation drawer formgroup
		const newReservationDrawerFormGroup = this.fb.group(
			{
				id: [lockerReservationDrawer.id],
				loadDate: [lockerReservationDrawer.loadDate],
				unloadDate: [lockerReservationDrawer.unloadDate],

				// drawer
				drawer: this.fb.group(
					{
						id: [lockerReservationDrawer.drawer.id],
						column: [lockerReservationDrawer.drawer.column],
						row: [lockerReservationDrawer.drawer.row],
						closed: [lockerReservationDrawer.drawer.closed],
						empty: [lockerReservationDrawer.drawer.empty],
					}),

				// article parts
				articleParts: this.fb.array([]),

				// helpers
				readArticlesEnabled: [false],
			});

		// add drawer reservation article parts
		if (lockerReservationDrawer.articleParts && lockerReservationDrawer.articleParts.length > 0)
		{
			lockerReservationDrawer.articleParts.forEach(articlePart =>
			{
				// reservation drawer article part
				const articlePartFormGroup = this.getReservationDrawerArticlePartFormArrayItem(articlePart);

				// push article part to reservation drawer
				(newReservationDrawerFormGroup.get('articleParts') as FormArray).push(articlePartFormGroup);
			});
		}

		// push reservation drawer to form
		this.formReservationDrawers.push(newReservationDrawerFormGroup);
	}
	getReservationDrawerArticlePartFormArrayItem(articlePart: LockerReservationDrawerArticlePart)
	{
		// new reservation drawer article part formgroup
		return this.fb.group(
			{
				id: [articlePart.id],
				rfidCode: [articlePart.rfidCode],
				alternativeCode: [articlePart.alternativeCode],
				notes: [articlePart.notes],

				// article
				article: this.fb.group(
					{
						id: [articlePart.article.id],
						qrCode: [articlePart.article.qrCode],
						notes: [articlePart.article.notes],

						// article type
						articleType: this.fb.group(
							{
								id: [articlePart.article.articleType.id],
								name: [articlePart.article.articleType.name],
								catalogCategoryName: [articlePart.article.articleType.catalogCategoryName],
								catalogArticleClassificationTypeName: [articlePart.article.articleType.catalogArticleClassificationTypeName],
								photoBase64: [articlePart.article.articleType.photoBase64]
							})
					})
			});
	}

	// start locker drawer load
	startLockerDrawerLoad()
	{
		// updating reservation locker drawers
		this.lockerReservationsService.startLoadingProcess(this.inputData.lockerReservationId, this.selectedDrawers.map(x => x.id)).subscribe(
			{
				next: (result) =>
				{
					// notify loading flow is started
					this.pcodeServices.notify('success', 'Procedura di caricamento iniziata');

					// reload current reservation
					this.loadLockerReservation();
				}
			})
	}

	// drawer load
	startDrawerLoad(reservationDrawerId: number)
	{
		// stop active drawers load
		this.stopDrawerLoad();

		// disable active readers
		this.formReservationDrawers.controls.forEach(x => { x.get('readArticlesEnabled').setValue(false); });

		// enable read articles on selected drawer
		this.formReservationDrawers.controls.find(x => x.get('id').value === reservationDrawerId).get('readArticlesEnabled').setValue(true);

		// start article readers
		this.startArticleReaders();
	}
	stopDrawerLoad()
	{
		// disable active readers
		this.formReservationDrawers.controls.forEach(x => { x.get('readArticlesEnabled').setValue(false); });

		// stop article readers
		this.stopArticleReaders();
	}
	openDrawer(drawerId: number)
	{
		this.lockerReservationsService.openDrawer(drawerId).subscribe({
			next: () =>
			{
				this.pcodeServices.notify('success', 'Apertura cassetto...')
			}
		})
	}
	addArticleToActiveReservationDrawer(entityIdentifier: number, readMode: string, callback?: any)
	{
		// current reservation drawer
		const activeReservationDrawerControl = this.formReservationDrawers.controls.find(x => x.get('readArticlesEnabled').value);
		const activeReservationDrawerId = activeReservationDrawerControl.get('id').value;

		// check: article part (rfid) or not (qrcode)
		switch (readMode)
		{
			case "rfid":
				{
					// check: article part not already added
					const existingArticlePartsIds = this.formReservationDrawers.controls
						.flatMap(x => { return x.get('articleParts').value as any[] })
						.flatMap(x => { return x.id });

					if (existingArticlePartsIds.indexOf(entityIdentifier) < 0)
					{
						this.lockerReservationsService.insertReadArticlePart(activeReservationDrawerId, entityIdentifier).subscribe(result =>
						{
							this.pushArticlePartToActiveReservationDrawer(activeReservationDrawerId, result);

							if (callback)
								callback();
						});
					}
					else
					{
						this.pcodeServices.notify('error', 'Questa parte è già stata aggiunta');

						if (callback)
							callback();
					}
				}
				break;

			case "qrcode":
				{
					this.lockerReservationsService.findInsertAvailableArticlePart(activeReservationDrawerId, entityIdentifier).subscribe(result =>
					{
						this.pushArticlePartToActiveReservationDrawer(activeReservationDrawerId, result);

						if (callback)
							callback();
					});
				}
				break;
		}
	}
	pushArticlePartToActiveReservationDrawer(reservationDrawerId: number, result: LockerReservationDrawerArticlePart)
	{
		// current reservation drawer (formarray)
		const activeFormReservationDrawer = this.formReservationDrawers.controls.find(x => x.get('id').value === reservationDrawerId);

		if (activeFormReservationDrawer)
		{
			// reservation drawer article part
			const articlePartFormGroup = this.getReservationDrawerArticlePartFormArrayItem(result);

			// add article to reservation drawer
			(activeFormReservationDrawer.get('articleParts') as FormArray).push(articlePartFormGroup);
		}
	}
	removeReservationDrawerArticlePart(reservationDrawerId: number, articlePartId: number, index: number)
	{
		if (confirm('Confermare la rimozione di questa parte dal caricamento del cassetto?'))
		{
			this.lockerReservationsService.removeReservationDrawerArticlePart(reservationDrawerId, articlePartId).subscribe(result =>
			{
				// remove from formarray
				(this.formReservationDrawers.controls.find(x => x.get('id').value === reservationDrawerId).get('articleParts') as FormArray).removeAt(index);
			})
		}
	}
	removeLockerReservationDrawer(reservationDrawerId: number)
	{
		if (confirm('Confermare la rimozione di questo cassetto per la prenotazione?'))
		{
			this.lockerReservationsService.removeReservationDrawer(reservationDrawerId).subscribe(result =>
			{
				// remove from formarray
				this.formReservationDrawers.removeAt(this.formReservationDrawers.controls.findIndex(x => x.get('id').value === reservationDrawerId));
			})
		}
	}

	// complete reservation load activity
	completeReservationLoadActivity()
	{
		if (confirm('Confermi di aver caricato e chiuso tutti i cassetti?'))
		{
			this.lockerReservationsService.completeReservationLoadActivity(this.lockerReservation.id).subscribe({
				next: () =>
				{
					// success notification
					this.pcodeServices.notify('success', 'Prenotazione evasa con successo.');

					// emit callback to parent
					this.loadCompleteCallback.emit();

					// hide modal
					this.hideModal();
				}
			})
		}
	}

	// qr code read
	startQrCodeScanner()
	{
		console.log('Lettura QRCODE in corso...');

		this.qrCodeScanner.start();
	}
	stopQrCodeScanner()
	{
		if (this.qrCodeScanner)
			this.qrCodeScanner.stop();

		console.log('Lettura QRCODE interrotta.');
	}
	qrCodeRead(data: ScannerQRCodeResult[])
	{
		if (!this.searchingArticle)
		{
			this.stopArticleReaders();
			this.searchingArticle = true;

			// search article
			this.lockerReservationsService.identifyResource(data[0].value, null).subscribe({
				next: result =>
				{
					if (result)
					{
						// add article to drawer
						this.addArticleToActiveReservationDrawer(result.id, 'qrcode', () =>
						{
							// restart article readers
							setTimeout(() =>
							{
								this.startArticleReaders();
								this.searchingArticle = false;
							}, 1000);
						});
					}
					else
					{
						// article part not found
						this.pcodeServices.notify('error', 'Risorsa non censita in Digifran.');

						// restart article readers
						setTimeout(() =>
						{
							this.startArticleReaders();
							this.searchingArticle = false;
						}, 1000);
					}
				},

				error: (e) =>
				{
					console.log(e);

					// restart article readers
					setTimeout(() =>
					{
						this.startArticleReaders();
						this.searchingArticle = false;
					}, 1000);
				}
			});
		}
	}

	// rfid read
	startRfidScan()
	{
		console.log('Lettura RFID in corso...');

		// start scan
		this.rfidScanInterval = setInterval(() =>
		{
			// focus on input element
			this.rfidInputElement.nativeElement.focus();

			this.readRfidInput();
		}, 500);
	}
	stopRfidScanner()
	{
		console.log('Lettura RFID interrotta.');

		this.clearRfidInput();
		clearInterval(this.rfidScanInterval);
	}
	readRfidInput()
	{
		if (!this.searchingArticle)
		{
			// set rfid read codes
			var readCodes = this.rfidInputElement.nativeElement.value ?
				(this.rfidInputElement.nativeElement.value as string)
					.split(/\r?\n|\r|\n/g)
					.filter(x => x !== '' && x.length === 24)
					.filter(this.uniqueFilter)
					.sort((x, y) => (x > y ? 1 : -1)) :
				[];

			// get first read code
			if (readCodes.length > 0)
			{
				// stop rfid scanner
				this.stopArticleReaders();
				this.searchingArticle = true;

				// search article
				this.lockerReservationsService.identifyResource(null, readCodes[0]).subscribe({
					next: result =>
					{
						if (result)
						{
							// add article to drawer
							this.addArticleToActiveReservationDrawer(result.part.id, 'rfid', () =>
							{
								// restart article readers
								this.startArticleReaders();
							});
						}
						else
						{
							// article part not found
							this.pcodeServices.notify('error', 'Risorsa non censita in Digifran.')

							// restart article readers
							this.startArticleReaders();
						}
					},

					error: (e) =>
					{
						console.error(e);

						// restart article readers
						this.startArticleReaders();
					},

					complete: () =>
					{
						// reset rfid input value
						this.clearRfidInput();

						// enable article search
						this.searchingArticle = false;
					}
				});
			}
			else
			{
				this.searchingArticle = false;
			}
		}
	}
	clearRfidInput()
	{
		// clear rfid input value
		if (this.rfidInputElement)
			this.rfidInputElement.nativeElement.value = '';
	}
	rfidInputBlur(e)
	{
		if (e.relatedTarget === null)
			e.target.focus();
	}
	uniqueFilter(value, index, self)
	{
		return self.indexOf(value) === index;
	}

	// update reservation drawers
	updateReservationDrawers()
	{

	}

	// rollback reservation to previous step
	rollbackToManagement()
	{
		if (confirm('Questa azione annullerà la configurazione attuale e riporterà la prenotazione alla selezione dei cassetti. Confermare?'))
		{
			this.lockerReservationsService.rollbackToManagement(this.inputData.lockerReservationId).subscribe({
				next: () =>
				{
					// update parent
					this.partialUpdateCallback.emit();

					// reinitialize component
					this.initialize();
				}
			})
		}
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}
}