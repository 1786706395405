import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdentifiedArticle } from '../../technologicalresources/articletypes/articletypes.models';
import { LockerReservation, LockerReservationDrawerArticlePart, LockerReservationStatus, LockerReservationType } from './reservations.models';

@Injectable()
export class LockerReservationsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get single
	public getSingle(id: number): Observable<LockerReservation>
	{
		return this.http.get<LockerReservation>(this.baseUrl + `api/warehouse/lockerReservations/getSingle/${id}`);
	}

	// update
	public insertUpdate(model)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/insertUpdate`, model);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(this.baseUrl + `api/warehouse/lockerReservations/delete/${id}`);
	}

	// get all locker reservation status
	public getAllLockerReservationStatus(): Observable<LockerReservationStatus[]>
	{
		return this.http.get<LockerReservationStatus[]>(this.baseUrl + `api/warehouse/lockerReservations/allLockerReservationStatus`);
	}

	// get all locker reservation types
	public getAllLockerReservationTypes(): Observable<LockerReservationType[]>
	{
		return this.http.get<LockerReservationType[]>(this.baseUrl + `api/warehouse/lockerReservations/allLockerReservationTypes`);
	}

	// start reservation loading process
	public startLoadingProcess(id: number, drawers: number[])
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/startLoadingProcess/${id}`, { drawers: drawers });
	}

	// complete reservation drawer load process
	public completeReservationLoadActivity(id: number)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/completeReservationLoadActivity/${id}`, null);
	}

	// identify resource
	public identifyResource(qrCode: string, rfidCode: string): Observable<IdentifiedArticle>
	{
		return this.http.post<IdentifiedArticle>(this.baseUrl + `api/warehouse/lockerReservations/identifyResource`, { qrCode: qrCode, rfidCode: rfidCode });
	}

	// insert read article part
	public insertReadArticlePart(reservationDrawerId: number, articlepartId: number): Observable<LockerReservationDrawerArticlePart>
	{
		return this.http.get<LockerReservationDrawerArticlePart>(this.baseUrl + `api/warehouse/lockerReservations/insertReadArticlePart/${reservationDrawerId}/${articlepartId}`);
	}

	// find and insert available article part
	public findInsertAvailableArticlePart(reservationDrawerId: number, articleId: number): Observable<LockerReservationDrawerArticlePart>
	{
		return this.http.get<LockerReservationDrawerArticlePart>(this.baseUrl + `api/warehouse/lockerReservations/findInsertAvailableArticlePart/${reservationDrawerId}/${articleId}`);
	}

	// remove reservation drawer
	public removeReservationDrawer(reservationDrawerId: number)
	{
		return this.http.delete(this.baseUrl + `api/warehouse/lockerReservations/removeReservationDrawer/${reservationDrawerId}`);
	}

	// remove reservation drawer article part
	public removeReservationDrawerArticlePart(reservationDrawerId: number, articlePartId: number)
	{
		return this.http.delete(this.baseUrl + `api/warehouse/lockerReservations/removeReservationDrawerArticlePart/${reservationDrawerId}/${articlePartId}`);
	}

	// rollback to management step
	public rollbackToManagement(id: number)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/rollbackToManagement/${id}`, null);
	}

	// rollback to drawer loading step
	public rollbackDrawerLoading(id: number)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/rollbackDrawerLoading/${id}`, null);
	}

	// cancel locker reservation
	public cancelLockerReservation(id: number)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/cancelLockerReservation/${id}`, null);
	}

	// open drawer
	public openDrawer(id: number)
	{
		return this.http.post(this.baseUrl + `api/warehouse/lockerReservations/openDrawer/${id}`, null);
	}
}