<div class="modal fade" bsModal #identifiTechnologicalResourceModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true" [config]="{ backdrop: 'static' }">
	<div class="modal-dialog" style="width: 800px; max-width:98%;">
		<div class="modal-content">

			<!--modal header-->
			<div class="modal-header">
				<h4 class="modal-title float-left">
					<i class="zmdi zmdi-search m-r-10"></i>
					<span>Identifica risorsa tecnologica</span>
				</h4>
			</div>

			<!--modal body-->
			<div class="modal-body">

				<!--search article-->
				<ng-container *ngIf="!currentArticle">

					<!--reaging-->
					<ng-container *ngIf="!articleNotRecognized">

						<!--info-->
						<div class="alert alert-info d-flex align-items-center">
							<mat-icon class="m-r-10">info_outline</mat-icon>
							<span>Inquadra il codice QR o scannerizza l'etichetta RFID per cercare l'articolo.</span>
						</div>

						<!--camera scanner / rfid scanner-->
						<ng-container>

							<!--qr code camera reading-->
							<div class="scanner_reader">
								<ngx-scanner-qrcode #qrCodeScanner="scanner" (event)="qrCodeRead($event)"></ngx-scanner-qrcode>
							</div>

							<!--rfid input-->
							<input #rfidInput (blur)="rfidInputBlur($event)" class="rfid_input" />

						</ng-container>

					</ng-container>

					<!--article not recognized-->
					<div class="alert alert-danger d-flex align-items-center" *ngIf="articleNotRecognized">
						<mat-icon class="m-r-10">info</mat-icon>
						<span>Articolo non riconosciuto.</span>
					</div>

				</ng-container>

				<!--article details-->
				<div class="article_details" *ngIf="currentArticle">

					<h1>{{currentArticle.catalogArticleTypeName}}</h1>

					<div class="d-flex align-items-start">

						<!--article details-->
						<ul class="article_details_list">
							<li>
								<strong>Categoria:</strong>
								<span>{{currentArticle.catalogCategoryName}}</span>
							</li>
							<li *ngFor="let attribute of currentArticle.attributes">
								<strong>{{attribute.name}}:</strong>
								<span>{{attribute.value}}</span>
							</li>
							<li>
								<strong>Parti di questa tipologia:</strong>
								<span>{{currentArticle.availableCount}} totale / {{(currentArticle.availableCount - currentArticle.assignedCount)}} non assegnat{{(currentArticle.availableCount - currentArticle.assignedCount > 1 ? 'e' : 'o')}}</span>
							</li>
							<li *ngIf="currentArticle.qrCode">
								<strong>QR Code:</strong>
								<span>{{currentArticle.qrCode}}</span>
							</li>
							<li *ngIf="currentArticle.notes">
								<strong>Note:</strong>
								<span>{{currentArticle.notes}}</span>
							</li>
							<ng-container *ngIf="currentArticle.part">
								<li>
									<strong>Condizione:</strong>
									<span>{{currentArticle.part.catalogArticleConditionName}}</span>
								</li>
								<li>
									<strong>Stato:</strong>
									<span>{{currentArticle.part.catalogArticleStatusName}}</span>
								</li>
								<li>
									<strong>Dipendente assegnato:</strong>
									<span>{{currentArticle.part.assignedEmployeeFullName || 'Non assegnato'}}</span>
								</li>
								<li *ngIf="currentArticle.part.location">
									<strong>Posizione:</strong>
									<span>{{currentArticle.part.location}}</span>
								</li>
								<li *ngIf="currentArticle.part.rfidCode">
									<strong>Codice RFID:</strong>
									<span>{{currentArticle.part.rfidCode}}</span>
								</li>
								<li *ngIf="currentArticle.part.notes">
									<strong>Note:</strong>
									<span>{{currentArticle.part.notes}}</span>
								</li>
							</ng-container>
						</ul>

						<!--thumbnail box-->
						<div *ngIf="currentArticle.photoBase64" class="thumbnail_box">
							<img [src]="'data:image/png;base64,' + currentArticle.photoBase64" title="Fotografia" />
						</div>

					</div>

				</div>

				<!--step actions-->
				<div class="actions_box">

					<!--cancel-->
					<button type="button" mat-button class="mr-2" (click)="closeModal()">
						<mat-icon class="m-r-10">close</mat-icon>
						<span>Chiudi</span>
					</button>

					<!--new article read-->
					<button type="button" mat-button class="mr-2" (click)="initialize()">
						<mat-icon class="m-r-10">search</mat-icon>
						<span>Nuova lettura</span>
					</button>

					<!--go to article details-->
					<button type="button" mat-raised-button color="accent" (click)="goToArticleDetails()" *ngIf="currentArticle">
						<mat-icon class="m-r-10">arrow_forward</mat-icon>
						<span>Dettagli</span>
					</button>

				</div>

			</div>

		</div>
	</div>
</div>