@mixin smd-fab-speed-dial-container($box-orient, $flex-direction)
{
	-webkit-box-orient: $box-orient;
	-webkit-box-direction: normal;
	-webkit-flex-direction: $flex-direction;
	flex-direction: $flex-direction;
}

@mixin smd-fab-speed-dial-box-order($ordinal-group, $order)
{
	-webkit-box-ordinal-group: $ordinal-group;
	-webkit-order: $order;
	order: $order;
}

@mixin smd-fab-speed-dial-actions($box-orient, $box-direction, $flex-direction, $ordinal-group, $order, $action-item-margin-direction)
{
	-webkit-box-orient: $box-orient;
	-webkit-box-direction: $box-direction;
	-webkit-flex-direction: $flex-direction;
	flex-direction: $flex-direction;

	@include smd-fab-speed-dial-box-order($ordinal-group, $order);

	& .smd-fab-action-item
	{
		margin-#{$action-item-margin-direction}: 10px;
	}
}

smd-fab-speed-dial
{
	display: inline-block;

	&.smd-opened
	{
		.smd-fab-speed-dial-container
		{
			smd-fab-trigger
			{
				&.smd-spin
				{
					-webkit-transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}
		}
	}

	.smd-fab-speed-dial-container
	{
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		z-index: 20;

		smd-fab-trigger
		{
			pointer-events: auto;
			z-index: 24;

			&.smd-spin
			{
				-webkit-transition: all .6s cubic-bezier(.4, 0, .2, 1);
				transition: all .6s cubic-bezier(.4, 0, .2, 1);
			}
		}

		smd-fab-actions
		{
			display: -webkit-box;
			display: -webkit-flex;
			display: flex;
			height: auto;
		}
	}

	&.smd-fling
	{
		.smd-fab-speed-dial-container
		{
			smd-fab-actions
			{
				& .smd-fab-action-item
				{
					display: block;
					opacity: 1;
					-webkit-transition: all .3s cubic-bezier(.55, 0, .55, .2);
					transition: all .3s cubic-bezier(.55, 0, .55, .2);
				}
			}
		}
	}

	&.smd-scale
	{
		.smd-fab-speed-dial-container
		{
			smd-fab-actions
			{
				& .smd-fab-action-item
				{
					-webkit-transform: scale(0);
					transform: scale(0);
					-webkit-transition: all .3s cubic-bezier(.55, 0, .55, .2);
					transition: all .3s cubic-bezier(.55, 0, .55, .2);
					-webkit-transition-duration: .14286s;
					transition-duration: .14286s;
				}
			}
		}
	}

	&.smd-down
	{
		.smd-fab-speed-dial-container
		{
			@include smd-fab-speed-dial-container(vertical, column);

			& smd-fab-trigger
			{
				@include smd-fab-speed-dial-box-order(2, 1);
			}

			& smd-fab-actions
			{
				@include smd-fab-speed-dial-actions(vertical, normal, column, 3, 2, top);
			}
		}
	}

	&.smd-up
	{
		.smd-fab-speed-dial-container
		{
			@include smd-fab-speed-dial-container(vertical, column);

			& smd-fab-trigger
			{
				@include smd-fab-speed-dial-box-order(3, 2);
			}

			& smd-fab-actions
			{
				@include smd-fab-speed-dial-actions(vertical, reverse, column-reverse, 2, 1, bottom);
			}
		}
	}

	&.smd-left
	{
		.smd-fab-speed-dial-container
		{
			@include smd-fab-speed-dial-container(horizontal, row);

			& smd-fab-trigger
			{
				@include smd-fab-speed-dial-box-order(3, 2);
			}

			& smd-fab-actions
			{
				@include smd-fab-speed-dial-actions(horizontal, normal, row-reverse, 2, 1, right);
			}
		}
	}

	&.smd-right
	{
		.smd-fab-speed-dial-container
		{
			@include smd-fab-speed-dial-container(horizontal, row);

			& smd-fab-trigger
			{
				@include smd-fab-speed-dial-box-order(2, 1);
			}

			& smd-fab-actions
			{
				@include smd-fab-speed-dial-actions(horizontal, normal, row, 3, 2, left);
			}
		}
	}
}

.mat-mini-fab .mat-button-wrapper
{
	padding: 0 !important;
}

.mat-mini-fab .mat-button-wrapper md-icon
{
	padding: 8px 0 !important;
}
